<template>
  <Modal v-model="showModal" :mask-closable="false" width="500px">
    <div slot="header">注册信息</div>
    <div class="showModalBox">
      <div class="inputBox ">
        <Input placeholder="输入真实姓名" v-model="form.name" class="inputBox-index">
        <template #prefix>
          <img src="../../../assets/user.png" alt="">
        </template>
        </Input>
      </div>
      <div class="inputBox mt20">
        <Input type="text" v-model="form.mobile" placeholder="输入手机号" class="inputBox-index">
        <template #prefix> <img src="../../../assets/phone.png" alt=""></template>
        </Input>
      </div>
      <div class="inputBox mt20">
        <Input placeholder="输入密码" v-model="form.password" type="password" password class="inputBox-index">
        <template #prefix>
          <img src="../../../assets/pswd.png" alt="">
        </template>
        </Input>
      </div>

      <div class="inputBox  mt20">
        <Input placeholder="输入验证码" v-model="captchaCode" class="inputBox-index">
        <template #prefix>
          <img src="../../../assets/captcha.png" alt="" />
        </template>
        </Input>
        <img :src="`//${this.domains.trans}/api/auth/getCaptchaImage?id=${captchaData}`" alt="" @click="throttle()" :key="captchaIndex" style="left: 337px;width: 129px; height: 52px;top: 1px;">
      </div>
      <div class="inputBox mt20">
        <Input type="text" v-model="form.code" placeholder="手机验证码" :key="codeIndex">
        <template #prefix>
          <img src="../../../assets/pswd.png" alt="" />
        </template>
        </Input>
        <div class="inputCode" :class="[isDisabled?'isDisabled':'']" @click="getCode" :key="codeCopywriting">
          {{codeCopywriting}}</div>
      </div>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="showModal=false">取消</Button>
      <Button type="primary" @click="ok">注册</Button>
    </div>
  </Modal>
</template>
<script>
export default {
  name: 'RegistUser',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: false,
      isDisabled: false,
      captchaData: '',
      captchaIndex: 0,
      codeCopywriting: '获取验证码',
      codeIndex: 0,
      canRun: null,
      time: 60,
      timer: null,
      captchaCode: '',
      form: {
        "mobile": "",//手机号
        "password": "",
        "code": "", //微信获取的手机短信码凭证
        "name": "", //用户真实姓名
      }
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.form.mobile = '';
        this.form.password = '';
        this.form.code = '';
        this.form.name = '';
        this.getCaptcha();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
    // 'form.mobile'() {
    //   this.form.code = '';
    //   this.codeIndex++;
    // },
  },
  mounted: function () {
  },
  methods: {
    isPhone() {
      let pattern = /^1[3456789]\d{9}$/;
      return pattern.test(this.form.mobile);
    },
    getCode() {
      if (!this.isPhone()) {
        this.$Message.warning(this.showLang('logon.enter.ok.phone'));
        return false
      }
      if (this.captchaCode.length != 4) {
        this.$Message.warning('请先输入图片验证码');
        return false
      }
      if (this.isDisabled) { return false }
      let ajaxData = {
        "mobile": this.form.mobile,
        "captchaId": this.captchaData, //获取登录验证码接口返回的验证码ID
        "captchaCode": this.captchaCode //验证码内容
      }
      this.$axios.post(`api/auth/GetRegistMessage`, ajaxData).then((res) => {
        if (res.code == 0) {
          this.isDisabled = true;
          this.timer = setInterval(() => {
            if (this.time == 0) {
              clearInterval(this.timer);
              this.isDisabled = false;
              this.codeCopywriting = '获取验证码';
              this.time = 60;
            } else {
              this.codeCopywriting = this.time + 's';
              this.time--;
            }
          }, 1000);
        }
      })

    },
    getCaptcha() {
      this.$axios.post(`api/auth/GetCaptchaCode`, {}).then(res => {
        if (res.code == 0) {
          this.captchaData = res.data;
          this.captchaIndex++;
        }
      });
    },
    throttle() {
      if (this.canRun == null) {
        this.getCaptcha();
        this.canRun = Date.now();
      } else {
        const currentTime = Date.now();
        if (currentTime - this.canRun >= 1000) {
          this.getCaptcha();
          this.canRun = currentTime;
        }
      }
    },
    ok() {
      this.$axios.post(`api/auth/RegistUser`, this.form).then(res => {
        if (res.code == 0) {
          this.$Message.info('注册成功');
          setTimeout(() => {
            this.showModal = false;
          }, 2000);
        }
      });
    },
  }
}
</script>
<style scoped>
.page-box {
  background-color: #fff;
  width: 100%;
  height: 100%;
}

.inputCode {
  position: absolute;
  right: 0;
  top: 0;
  width: 155px;
  height: 54px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  border-radius: 0 5px 5px 0;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid #009dff;
  background-color: #009dff;
  line-height: 54px;
}
.inputCode.isDisabled {
  opacity: 0.5;
}

input {
  width: 343px;
  height: 54px;
  margin: 10px;
  display: flex;
  color: #aab0be;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  background-color: #ffffff !important;
  position: relative;
  border-radius: 6px;
  border: 0;
  outline-style: none;
  align-self: stretch;
  line-height: 54px;
  text-indent: 54px;
}
input::-webkit-input-placeholder {
  /* WebKit browsers */
  font-size: 16px;
  background-color: #ffffff !important;
  position: relative;
  color: #aab0be;
  line-height: 54px;
  text-indent: 54px;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-size: 16px;
  background-color: #ffffff !important;
  position: relative;
  color: #aab0be;
  line-height: 54px;
  text-indent: 54px;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  font-size: 16px;
  background-color: #ffffff !important;
  font-weight: 400;
  position: relative;
  color: #aab0be;
  line-height: 54px;
  text-indent: 54px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 111111s;
  -webkit-transition: color 11111s ease-out, background-color 111111s ease-out;
}

.inputBox {
  position: relative;
}
.inputBox-index {
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff !important;
  position: relative;
  border-radius: 6px;
  border: 0;
  outline-style: none;
  align-self: stretch;
  text-indent: 54px;
}
.showModalBox >>> .ivu-input {
  height: 54px;
  text-indent: 16px;
  color: #aab0be;
  font-size: 18px;
  caret-color: #009dff;
  /* caret-width: 5px; */
}
.inputBox img {
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 50;
}
.showQrCode {
  width: 180px;
  height: 180px;
  background-color: rgba(0, 157, 255, 0.5);
  position: absolute;
  left: 50%;
  margin-left: -90px;
  margin-top: 0;
  top: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
}
.showQrCode p {
  margin-top: 30px;
  color: #f00;
}
.showQrCode img {
  width: 50px;
  height: 50px;
  margin: 0 auto 20px;
}
</style>

import Vue from 'vue'

const tools = {
  buildTree(items, parentId = '') {
    return items.filter(item => item.parentId === parentId).map(item => ({ ...item, children: this.buildTree(items, item.id), }));
  },
}
const state = {
  menusId: '',
  myMenus: {},
  isSiteNav: [{
    actImg: "navIconAc18", code: "11501", id: "2a347b5fc3d646058d25a0b2acf4e471", img: "navIcon18", name: "平台管理", parentId: "", router: "/index/platform", sort: 150
    , series: 11501
  }, {
    actImg: "navIconAc17", code: "11401", id: "2a347b5fc3d646058d25a0b2acf4e472", img: "navIcon17", name: "生产管理", parentId: "", router: "/index/platform", sort: 140
    , series: 11401
  }
  ],
  navData: [
    { code: 10001, router: '', img: 'navIcon1', actImg: 'navIconAc1', series: 10001 },
    { code: 10101, router: '/index/powerbox', img: 'navIcon2', actImg: 'navIconAc2', series: 10100 },
    { code: 10104, router: '/index/powerbox', img: 'navIcon1', actImg: '', series: 10100 },
    { code: 10102, router: '/index/powerbox', img: 'navIcon2', actImg: '', series: 10100 },
    { code: 10103, router: '/index/light', img: 'navIcon3', actImg: '', series: 10100 },
    { code: 10105, router: '/index/transformer', img: 'navIcon3', actImg: '', series: 10100 },

    { code: 10201, router: '', img: 'navIcon4', actImg: 'navIconAc4', series: 10201 },
    { code: 10301, router: '', img: 'navIcon5', actImg: 'navIconAc5', series: 10301 },
    { code: 10401, router: '', img: 'navIcon6', actImg: 'navIconAc6', series: 10401 },
    { code: 10501, router: '/index/video', img: 'navIcon6', actImg: 'navIconAc6', series: 10501 },
    { code: 10601, router: '', img: 'navIcon7', actImg: 'navIconAc7', series: 10601 },
    { code: 10701, router: '', img: 'navIcon8', actImg: 'navIconAc8', series: 10701 },
    { code: 10801, router: '', img: 'navIcon11', actImg: 'navIconAc11', series: 10801 },
    { code: 10901, router: '', img: 'navIcon12', actImg: 'navIconAc12', series: 10901 },
    { code: 11001, router: '', img: 'navIcon13', actImg: 'navIconAc13', series: 11001 },
    { code: 11101, router: '', img: 'navIcon14', actImg: 'navIconAc14', series: 11101 },
    { code: 11201, router: '', img: 'navIcon15', actImg: 'navIconAc15', series: 11201 },
    { code: 11301, router: '/index/auth', img: 'navIcon16', actImg: 'navIconAc16', series: 11301 },
    { code: 11401, router: '', img: 'navIcon17', actImg: 'navIconAc17', series: 11401 },
    { code: 11501, router: '/index/platform', img: 'navIcon18', actImg: 'navIconAc18', series: 11501 },
  ],
  funData: [
    { code: 'n001', router: '', img: '', actImg: '' },
    { code: 'n002', router: '', img: '', actImg: '' },
    { code: 'n003', router: '', img: '', actImg: '' },
    { code: 'n004', router: '', img: '', actImg: '' },
  ],
}
const mutations = {
  setMenusId: function (state, params) {
    state.menusId = params;
  },
  setMyMenus: function (state, params) {
    state.myMenus = params;
    localStorage.setItem('myMenus', JSON.stringify(state.myMenus));
  },
  init: function (state) {
    state.myMenus = JSON.parse(localStorage.getItem('myMenus'));
  },
}
const actions = {
  getMyMenus: function (context) {
    return new Promise(resolve => {
      Vue.$axios.post(`api/auth/GetMyMenus`, {}).then(res => {
        if (res.code === 0) {
          context.commit('setMyMenus', res.data);
        }
        resolve(res);
      });
    });
  },
}
const getters = {
  groupArr: function (state) {
    return function (groupId) {
      return state + groupId;
    }
  },
  groupStationArr: function (state) {
    return function (groupId) {
      return state + groupId;
    }
  },
  groupTree: function (state) {
    return function (groupId) {
      // return tools.getGroupPathName(state.groups, groupId);
      return tools.buildTree(state, groupId);
    }
  },
  groupStationTree: function (state) {
    return function (groupId) {
      // return tools.getGroupPathName(state.groups, groupId);
      return state + groupId;
    }
  },
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
// import Vue from 'vue'

// const tools = {

// }
const state = {
  //单灯
  lightIns: [
    { code: "getOutputArgs", needPswd: false, name: "查询调光参数" },
    { code: "getClockArgs", needPswd: false, name: "查询自动校时参数" },
    { code: "getclock", needPswd: false, name: "查询时钟" },
    { code: "getRatedPower", needPswd: false, name: "查询额定功率" },
    { code: "getUploadMode", needPswd: false, name: "查询自动上报模式" },
    { code: "getVoltageOutputArgs", needPswd: false, name: "查询电压调光输出参数" },
    { code: "getPWMOutputArgs", needPswd: false, name: "查询PWM调光输出参数" },
    { code: "getOutputMode", needPswd: false, name: "查询调光控制模式" },
    { code: "getOutputPowerLimit", needPswd: false, name: "查询输出功率限值" },
    { code: "getOutputVoltageCompensation", needPswd: false, name: "查询输出电压补偿" },
    { code: "getDayPlan1", needPswd: false, name: "查询计时日表" },
    { code: "getDayPlan2", needPswd: false, name: "查询准时日表" },
    { code: "getPlanType", needPswd: false, name: "查询控制模式" },
    { code: "getSigFlag", needPswd: false, name: "查询信号灯参数" },
    { code: "getInfo", needPswd: false, name: "查询设备信息" },
    { code: "getSimInfo", needPswd: false, name: "查询SIM卡信息" },
    { code: "getGroup", needPswd: false, name: "查询分组" },
    { code: "getLuxOpenValue", needPswd: false, name: "查询光感控制的开灯值" },
    { code: "getUnionBright", needPswd: false, name: "查询联动亮度" },
    { code: "getUnionParam", needPswd: false, name: "查询联动参数" },
    { code: "get433Args", needPswd: false, name: "查询433参数" },
    { code: "get433Plan", needPswd: false, name: "查询433日表" },
    { code: "get433Id", needPswd: false, name: "查询433ID" },
    { code: "getLatLng", needPswd: false, name: "查询经纬度" },
    { code: "getLatLngArgs", needPswd: false, name: "查询经纬度参数" },
    { code: "getAlarmRange", needPswd: false, name: "查询报警阈值" },
    { code: "getAlarmStatus", needPswd: false, name: "查询报警开关" },
    { code: "getGyroLevel", needPswd: false, name: "查询倾斜阈值" },
    { code: "getLeakageAlarmLevel", needPswd: false, name: "查询漏电报警级别" },
    { code: "getLeakageAlarmEnable", needPswd: false, name: "查询漏电报警使能" },
    { code: "getLeakageAlarmStatus", needPswd: false, name: "查询漏电报警状态" },
    { code: "getChipVolCurArgs", needPswd: false, name: "查询计量芯片电压电流功率系数" },
    { code: "getAdjustParams", needPswd: false, name: "查询计量单元校准系数" },
    { code: "getChipVoltage", needPswd: false, name: "查询电压校准系数" },
    { code: "getChipCurrent", needPswd: false, name: "查询电流校准系数" },
    { code: "getChipPower", needPswd: false, name: "查询功率校准系数" },
    { code: "getLoraWork", needPswd: false, name: "查询LORA工作参数" },
    { code: "getNetwork", needPswd: false, name: "查询工作网络" },
    { code: "getNetArgs", needPswd: false, name: "查询网络参数" },
    { code: "getAPN", needPswd: false, name: "查询APN" },
    { code: "getftp", needPswd: false, name: "查询FTP升级参数" },
    { code: "getSNID", needPswd: false, name: "查询SNID" },
    { code: "getRelayLevel", needPswd: false, name: "查询中继级别" },
    { code: "getLog", needPswd: false, name: "查询累计记录" },
    { code: "getStationInfo", needPswd: false, name: "查询基站信息" },
    { code: "forceRead", needPswd: true, name: "召测数据" },
    { code: "openSingle", needPswd: true, name: "开灯" },
    { code: "closeSingle", needPswd: true, name: "关灯" },
    { code: "handSingle", needPswd: true, name: "调光" },
    { code: "setOutputArgs", needPswd: true, name: "设置调光参数" },
    { code: "setClockArgs", needPswd: true, name: "设置自动校时参数" },
    { code: "setclock", needPswd: true, name: "校准时钟" },
    { code: "setRatedPower", needPswd: true, name: "设置额定功率" },
    { code: "setUploadMode", needPswd: true, name: "设置自动上报模式" },
    { code: "setVoltageOutputArgs", needPswd: true, name: "设置电压调光输出参数" },
    { code: "setPWMOutputArgs", needPswd: true, name: "设置PWM调光输出参数" },
    { code: "setOutputPowerLimit", needPswd: true, name: "设置输出功率限值" },
    { code: "setOutputVoltageCompensation", needPswd: true, name: "设置输出电压补偿" },
    { code: "setDayPlan1", needPswd: true, name: "设置计时日表" },
    { code: "setDayPlan2", needPswd: true, name: "设置准时日表" },
    { code: "setPlanType", needPswd: true, name: "设置控制模式" },
    { code: "setSigFlag", needPswd: true, name: "设置信号灯参数" },
    { code: "setGroup", needPswd: true, name: "设置分组" },
    { code: "setLuxOpenValue", needPswd: true, name: "设置光感控制的开灯值" },
    { code: "setUnionBright", needPswd: true, name: "设置联动亮度" },
    { code: "set433Args", needPswd: true, name: "写入433参数" },
    { code: "set433Plan", needPswd: true, name: "写入433日表" },
    { code: "set433Id", needPswd: true, name: "写入433ID" },
    { code: "send433Adjust", needPswd: true, name: "433调光" },
    { code: "setLatLng", needPswd: true, name: "设置经纬度" },
    { code: "setLatLngArgs", needPswd: true, name: "设置经纬度参数" },
    { code: "setAlarmRange", needPswd: true, name: "设置报警阈值" },
    { code: "setAlarmStatus", needPswd: true, name: "设置报警开关" },
    { code: "setGyroLevel", needPswd: true, name: "设置倾斜阈值" },
    { code: "setLeakageAlarmLevel", needPswd: true, name: "设置漏电报警级别" },
    { code: "setLeakageAlarmEnable", needPswd: true, name: "设置漏电报警使能" },
    { code: "adjustChipBase", needPswd: true, name: "计量芯片校准" },
    { code: "adjustChipVoltage", needPswd: true, name: "设置电压校准系数" },
    { code: "adjustChipCurrent", needPswd: true, name: "设置电流校准系数" },
    { code: "adjustChipPower", needPswd: true, name: "设置功率校准系数" },
    { code: "setLoraWork", needPswd: true, name: "设置LORA工作参数" },
    { code: "setNetwork", needPswd: true, name: "设置工作网络" },
    { code: "setNetArgs", needPswd: true, name: "设置网络参数" },
    { code: "setAPN", needPswd: true, name: "设置APN" },
    { code: "update", needPswd: true, name: "OTA升级" },
    { code: "ftp", needPswd: true, name: "设置FTP升级参数" },
    { code: "reset", needPswd: true, name: "重启设备" },
    { code: "init", needPswd: true, name: "初始化" },
    { code: "clearEnergyNumber", needPswd: true, name: "清除能耗计数" },
    { code: "resetSNID", needPswd: true, name: "复位SNID" },
    { code: "setRelayLevel", needPswd: true, name: "设置中继级别" },
    { code: "clearLog", needPswd: true, name: "清零累计记录" }
  ],
  //配电箱
  boxIns: [
    { code: "getclock", needPswd: false, name: "查询时钟" },
    { code: "getCmdCount", needPswd: false, name: "查询指令数量" },
    { code: "getInfo", needPswd: false, name: "查询设备信息" },
    { code: "getSimInfo", needPswd: false, name: "查询SIM卡信息" },
    { code: "getVoltageRange", needPswd: false, name: "查询电压报警上下限" },
    { code: "getComArgs", needPswd: false, name: "查询串口参数" },
    { code: "getNetwork", needPswd: false, name: "查询工作网络" },
    { code: "getDefaultNetwork", needPswd: false, name: "查询默认网络" },
    { code: "getftp", needPswd: false, name: "查询FTP升级参数" },
    { code: 'forceRead', needPswd: true, name: '召测数据' },
    { code: 'setclock', needPswd: true, name: '校准时钟' },
    { code: 'clearCmds', needPswd: true, name: '清除指令队列' },
    { code: 'setVoltageRange', needPswd: true, name: '设置电压报警上下限' },
    { code: 'setComArgs', needPswd: true, name: '设置串口参数' },
    { code: 'setNetwork', needPswd: true, name: '设置工作网络' },
    { code: 'setDefaultNetwork', needPswd: true, name: '设置默认网络' },
    { code: 'update', needPswd: true, name: 'OTA升级' },
    { code: 'ftp', needPswd: true, name: '设置FTP升级参数' },
    { code: 'reset', needPswd: true, name: '重启设备' },
    { code: 'init', needPswd: true, name: '初始化' }],
  //集中器
  monitorIns: [
    { code: "getclock", needPswd: false, name: "查询时钟" },
    { code: "getWhite", needPswd: false, name: "查询白名单" },
    { code: "getCmdCount", needPswd: false, name: "查询指令数量" },
    { code: "getInfo", needPswd: false, name: "查询设备信息" },
    { code: "getSimInfo", needPswd: false, name: "查询SIM卡信息" },
    { code: "getLoraWork", needPswd: false, name: "查询LORA工作参数" },
    { code: "getWhiteFlag", needPswd: false, name: "查询单灯相位" },
    { code: "getNetwork", needPswd: false, name: "查询工作网络" },
    { code: "getLightUpdate", needPswd: false, name: "查询单灯升级" },
    { code: "getSaveOtaFile", needPswd: false, name: "查询保存OTA文件" },
    { code: "getK5Network", needPswd: false, name: "查询K5网络" } ,
    { code: "forceReadLight", needPswd: true, name: "召测镜像" },
    { code: "handGroup11", needPswd: true, name: "组开" },
    { code: "handGroup10", needPswd: true, name: "组关" },
    { code: "handGroup1", needPswd: true, name: "组调" },
    { code: "handAll1", needPswd: true, name: "群开" },
    { code: "handAll0", needPswd: true, name: "群关" },
    { code: "handAll", needPswd: true, name: "群调" },
    { code: "setclock", needPswd: true, name: "校准时钟" },
    { code: "setallclock", needPswd: true, name: "群校时钟" },
    { code: "setWhite", needPswd: true, name: "设置白名单" },
    { code: "clearCmds", needPswd: true, name: "清除指令队列" },
    { code: "setLoraWork", needPswd: true, name: "设置LORA工作参数" },
    { code: "startFindSlave", needPswd: true, name: "开始查找从机" },
    { code: "stopFindSlave", needPswd: true, name: "停止查找从机" },
    { code: "startReadWhiteFlag", needPswd: true, name: "开始识别相位" },
    { code: "stopReadWhiteFlag", needPswd: true, name: "停止识别相位" },
    { code: "getMonitorSnId", needPswd: true, name: "查SNID" },
    { code: "setNetwork", needPswd: true, name: "设置工作网络" },
    { code: "startNet", needPswd: true, name: "Lora组网" },
    { code: "update", needPswd: true, name: "OTA升级" },
    { code: "setLightUpdate", needPswd: true, name: "设置单灯升级" },
    { code: "setSaveOtaFile", needPswd: true, name: "设置保存OTA文件" },
    { code: "startSaveOtaFile", needPswd: true, name: "开始下发OTA文件" },
    { code: "reset", needPswd: true, name: "重启设备" },
    { code: "init", needPswd: true, name: "初始化" },
    { code: "setK5Network", needPswd: true, name: "设置K5网络" }
  ],
  //电流通道
  currentIns: [
    { code: "getCurrentRate", needPswd: false, name: "查询倍率" },
    { code: "getCurrentRange", needPswd: false, name: "查询报警上下限" },
    { code: "setCurrentRate", needPswd: true, name: "设置倍率" },
    { code: "setCurrentRange", needPswd: true, name: "设置报警上下限" }
  ],
  // 控制通道
  outputIns: [
    { code: "getWeekControl", needPswd: false, name: "查询周计划" },
    { code: "getTimeEnable", needPswd: false, name: "查询时段" },
    { code: "handControl", needPswd: true, name: "开关灯" },
    { code: "getYearPlan", needPswd: true, name: "读取时间表" },
    { code: "setYearPlan", needPswd: true, name: "下发时间表" },
    { code: "setWeekControl", needPswd: true, name: "设置周计划" },
    { code: "setTimeEnable", needPswd: true, name: "设置时段" }
  ],

}
const mutations = {

}
const getters = {

}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  // actions,
};
<template>
  <Modal v-model="showModal" :mask-closable="false" width="900px">
    <div slot="header">邀请列表</div>
    <div>
      <vxe-table border ref='xTable1' :row-config="{isHover: true}" :data="inviteList" :radio-config="{ highlight: true}" :key="indexRefresh">
        <vxe-column field="projectName" title="邀请项目名称"></vxe-column>
        <!-- <vxe-column field="Inviter" title="邀请人"></vxe-column> -->
        <vxe-column field="inviteTime" title="时间"></vxe-column>
        <vxe-column width="200" fixed="right">
          <template slot="header">
            <Button type="success" @click="refreshTab()" class="ml10">刷新</Button>
          </template>
          <template #default="{ row }">
            <Button type="primary" @click="accept(row)">接受</Button>
            <Button type="warning" @click="refuse(row)" class="ml10">拒绝</Button>
          </template>
        </vxe-column>
      </vxe-table>
      <ProjectList v-model="projectShow"></ProjectList>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="showModal=false">取消</Button>
      <Button type="primary" @click="ok">确定</Button>
    </div>
  </Modal>
</template>
<script>

import ProjectList from './ProjectList.vue'
export default {
  name: 'Inviter',
  components: {
    ProjectList
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Array,
      default() { return []; }
    },
  },
  data() {
    return {
      showModal: false,
      projectShow: false,
      indexRefresh: 0,
      inviteList: [],
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.AcceptInvite();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },

  },
  mounted: function () {
  },
  methods: {
    AcceptInvite() {
      this.$axios.post(`api/auth/GetInviteList`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'inviteList', res.data)
        }
      });
    },
    accept(el) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定接受项目：${el.projectName}]邀请吗?`,
        onOk: async () => {
          this.$axios.post(`api/auth/AcceptInvite`, { id: el.id }).then(res => {
            if (res.code == 0) {
              this.$Message.info("加入成功");
              this.GetCommonRoles()
            }
          });
        }
      });
    },
    refuse(el) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定拒绝项目：${el.projectName}]邀请吗?`,
        onOk: async () => {
          this.$axios.post(`api/auth/DeleteCommonRole`, { id: el.id }).then(res => {
            if (res.code == 0) {
              this.$Message.info("删除成功");
              this.GetCommonRoles()
            }
          });
        }
      });
    },
    refreshTab() {
      this.indexRefresh();
    },
    ok() {
      this.projectShow = true;
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.page-box {
  background-color: #fff;
  width: 100%;
  height: 100%;
}
.alarm-data-area {
  width: 100%;
}
.role-operations {
  height: 500px;
  overflow-y: auto;
}
</style>

<template>
  <Modal v-model="showModal" :mask-closable="false" width="900px">
    <div slot="header">项目列表</div>
    <div>
      <div class="search-box">
        名称：
        <Input v-model="form.name" style="width: 200px" class="mr20"></Input>
        地址：
        <Input v-model="form.area" style="width: 200px"></Input>
        <Button type="primary" @click="refreshTab()" class="ml10">搜索</Button>
      </div>
      <vxe-table border ref='xTable1' :row-config="{isHover: true}" :data="list" :radio-config="{ highlight: true}" :key="indexRefresh" class="mt20">
        <vxe-column field="name" title="项目名称"></vxe-column>
        <vxe-column field="area" title="地址"></vxe-column>
        <vxe-column width="160" fixed="right">
          <template slot="header">
            <Button type="success" @click="addProject()">新增</Button>
          </template>
          <template #default="{ row }">
            <Button type="primary" @click="switchProject(row)">切换</Button>
          </template>
        </vxe-column>
      </vxe-table>
      <vxe-pager :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']" :current-page.sync="form.index" :page-size.sync="form.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange">
      </vxe-pager>
      <AddProjectList v-model="showAddProjectList"></AddProjectList>
    </div>

    <div slot="footer">
      <Button type="primary" @click="showModal=false">确定</Button>
    </div>
  </Modal>
</template>
<script>
import AddProjectList from './AddProjectList'

export default {
  name: 'ProjectList',
  components: {
    AddProjectList
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Array,
      default() { return []; }
    },
  },
  data() {
    return {
      showModal: false,
      showAddProjectList: false,
      indexRefresh: 0,
      total: 0,
      pageSizes: [20, 100, 500, 1000, 5000],
      list: [],
      form: {
        "name": "",
        "area": "",
        "index": 1,
        "size": 20
      }
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.getList()
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },

  },
  mounted: function () {
  },
  methods: {
    addProject() {
      this.showAddProjectList = true;

    },
    handlePageChange({ currentPage, pageSize }) {
      this.form.index = currentPage
      this.form.size = pageSize
      this.getList(true)
    },
    getList() {
      this.$axios.post(`api/auth/GetProjectList`, this.form).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data.list)
          this.total = res.data.count;
        }
      });
    },
    switchProject(el) {
      this.$axios.post(`api/auth/SwitchProject`, { id: el.id }).then(res => {
        if (res.code == 0) {
          this.GetMyMenus();
        }
      });
    },
    GetMyMenus() {
      this.$axios.post(`api/auth/GetMyMenus`, {}).then(res => {
        if (res.code == 0) {
          this.$router.push('/index');
          this.$store.dispatch('sys/getMyMenus')
        }
      });
    },
    refreshTab() {
      this.form.index = 1;
      this.getList();
    },

  }
}
</script>
<style scoped>
.page-box {
  background-color: #fff;
  width: 100%;
  height: 100%;
}
.alarm-data-area {
  width: 100%;
}
.role-operations {
  height: 500px;
  overflow-y: auto;
}
</style>

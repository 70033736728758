import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../pages/Login.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  component: Login
},
{
  path: '/login',
  component: Login
},
{
  path: '/index',
  name: 'Index',
  component: () => import('../pages/Index.vue'),
  children: [
    {
      path: 'home',
      component: () => import('../views/Home.vue')
    },
    {
      path: 'powerbox',
      component: () => import('../pages/powerbox/Index.vue')
    },
    {
      path: 'transformer',
      component: () => import('../pages/transformer/Index.vue')
    },
    {
      path: 'light',
      component: () => import('../pages/light/Index.vue')
    },
    {
      path: 'auth',
      component: () => import('../pages/auth/Index.vue')
    },
    {
      path: 'platform',
      component: () => import('../pages/platform/Index.vue')
    },
    {
      path: 'video',
      component: () => import('../pages/video/Index.vue')
    },
  ]
},

// {
//   path: '/index',
//   name: 'Index',
//   component: () => import('../views/Index.vue'),
//   children: [
//     //首页菜单
//     {
//       path: 'home',
//       component: () => import( /* webpackChunkName: "home" */ '../views/Home.vue')
//     },
//     //智慧照明
//     {
//       path: 'light',
//       component: () => import( /* webpackChunkName: "light" */ '../views/light/Index.vue'),
//       children: [
//         //道路照明
//         {
//           path: 'road',
//           component: () => import( /* webpackChunkName: "light" */ '../views/light/road/Index.vue'),
//           children: [
//             //展示首页
//             {
//               path: 'indexHome',
//               component: () => import( /* webpackChunkName: "light-road-home" */ '../views/light/road/Home.vue')
//             },
//             //demo首页
//             {
//               path: 'demoHome',
//               component: () => import( /* webpackChunkName: "light-road-home" */ '../views/light/road/HomeDemo.vue')
//             },
//             //系统概览
//             {
//               path: 'home',
//               component: () => import( /* webpackChunkName: "light-road-home" */ '../views/light/road/Home.vue')
//             },
//             //照明监控
//             {
//               path: 'monitor',
//               component: () => import( /* webpackChunkName: "light-road-monitor" */ '../views/light/road/monitor/Index.vue')
//             },
//             //地图
//             {
//               path: 'gis',
//               component: () => import( /* webpackChunkName: "light-road-gis" */ '../views/light/road/gis/Index.vue')
//             },
//             //光照度管理
//             {
//               path: 'lux',
//               component: () => import( /* webpackChunkName: "light-road-lux" */ '../views/light/road/lux/Index.vue')
//             },
//             //资产管理
//             {
//               path: 'asset',
//               component: () => import( /* webpackChunkName: "site-asset" */ '../views/asset/Index.vue')
//             },
//             //运维管理
//             // {path: 'work', component: () => import(/* webpackChunkName: "light-road-workflow" */ '../views/light/road/work/Index.vue')},
//             // {path: 'work', component: () => import(/* webpackChunkName: "light-road-workflow" */ '../views/work/Road.vue')},
//             {
//               path: 'work',
//               // component: () => import( /* webpackChunkName: "light-road-workflow" */ '../views/work/Index.vue')
//               component: () => import(/* webpackChunkName: "light-road-workflow" */ '../views/work/Road.vue')
//             },
//             //数据分析
//             {
//               path: 'analyse',
//               component: () => import( /* webpackChunkName: "light-road-analyse" */ '../views/light/road/analyse/Index.vue')
//             },
//             //工单管理
//             // {path: 'workorder', props: { sys: 'light/road' }, component: () => import(/* webpackChunkName: "light-road-workorder" */ '../views/workorder/Index.vue'), children: [
//             //   //  管养周期管理
//             //   {path: 'config', component: () => import(/* webpackChunkName: "light-workorder" */ '../views/workorder/config/Index.vue')},
//             //   //  工单管理
//             //   {path: 'record', component: () => import(/* webpackChunkName: "light-workorder" */ '../views/workorder/record/Index.vue')},
//             //   //  工单处理
//             //   {path: 'handle', component: () => import(/* webpackChunkName: "light-workorder" */ '../views/workorder/handle/Index.vue')},
//             //   //  工单故障统计
//             //   {path: 'statisticlevel', component: () => import(/* webpackChunkName: "light-workorder" */ '../views/workorder/statistic/Level.vue')},
//             //   //  工单状态统计
//             //   {path: 'statisticstatus', component: () => import(/* webpackChunkName: "light-workorder" */ '../views/workorder/statistic/Status.vue')},
//             // ]},
//             //系统管理
//             {
//               path: 'system',
//               component: () => import( /* webpackChunkName: "site-system" */ '../views/sys/Index.vue')
//             },
//           ]
//         },
//         //工厂照明
//         {
//           path: 'factory',
//           component: () => import('../views/light/factory/Index.vue'),
//           children:[ {
//             path: 'home',
//             component: () => import('../views/light/factory/Index.vue')
//           },]
//         },
//         //单灯专用版
//         {
//           path: 'single',
//           component: () => import('../views/light/factory/carat/Index.vue'),
//           // children:[ {
//           //   path: 'home',
//           //   component: () => import('../views/light/factory/Index.vue')
//           // },]
//         },
//       ]
//     },
//     //智慧灯杆
//     {
//       path: 'pole',
//       component: () => import( /* webpackChunkName: "pole" */ '../views/pole/Index.vue'),
//       children: [
//         //系统概览
//         {
//           path: 'home',
//           component: () => import( /* webpackChunkName: "pole-home" */ '../views/pole/Home.vue')
//         },
//         //灯杆管理
//         {
//           path: 'monitor',
//           component: () => import( /* webpackChunkName: "pole-monitor" */ '../views/pole/Monitor.vue'),
//           children: [{
//               path: 'pole'
//             },
//             {
//               path: 'light'
//             },
//             {
//               path: 'video'
//             },
//             //广播管理
//             {
//               path: 'broad'
//             },
//             //对讲管理
//             {
//               path: 'speak'
//             },
//             //广告屏管理
//             {
//               path: 'led'
//             },
//             //环境监测
//             {
//               path: 'env'
//             },
//             //WIFI管理
//             {
//               path: 'wifi'
//             },
//             //充电桩管理
//             {
//               path: 'charge'
//             },
//             //井盖管理
//             {
//               path: 'manhole'
//             },
//             //垃圾桶管理
//             {
//               path: 'garbage'
//             },
//             //交通管理
//             {
//               path: 'traffic'
//             },
//             //手机充电
//             {
//               path: 'phone'
//             },
//             //GIS地图
//             {
//               path: 'gis'
//             },
//             //数据分析
//             {
//               path: 'analyse'
//             },
//             //联动管理
//             {
//               path: 'union'
//             },
//           ]
//         },
//         //运维管理
//         {
//           path: 'gis',
//           component: () => import( /* webpackChunkName: "pole-gis" */ '../views/pole/Working.vue')
//         },
//         //资产运维
//         {
//           path: 'asset',
//           component: () => import( /* webpackChunkName: "pole-asset" */ '../views/pole/asset/Index.vue')
//         },
//         //数据分析
//         {
//           path: 'analyse',
//           component: () => import( /* webpackChunkName: "pole-analyse" */ '../views/pole/Working.vue')
//         },
//         //系统管理
//         // {path: 'system', component: () => import(/* webpackChunkName: "pole-system" */ '../views/auth/Index.vue')},
//         {
//           path: 'system',
//           component: () => import( /* webpackChunkName: "site-system" */ '../views/sys/Index.vue')
//         },
//         //运维管理
//         {
//           path: 'business',
//           component: () => import( /* webpackChunkName: "pole-business" */ '../views/pole/business/Index.vue')
//         },
//       ]
//     },
//   ]
// },
{
  path: '/main',
  name: 'MainPage',
  component: () => import('../views/MainPage.vue')
}
]

const router = new VueRouter({
  mode: 'history',
  ops: [],
  user: {},
  base: process.env.BASE_URL,
  routes
})

export default router
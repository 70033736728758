<template>
  <Modal v-model="showModal" :mask-closable="false" width="600px">
    <div slot="header">新增项目</div>
    <div>
      <Form ref="form" :model="form" :label-width="100">
        <FormItem prop="name" label="项目名称">
          <Input v-model="form.name" style="width: 200px"></Input>
        </FormItem>
        <FormItem prop="remark" label="项目地址">
          <Input v-model="form.area" style="width: 200px"></Input>
        </FormItem>
        <FormItem prop="remark" label="经度">
          <Input v-model="form.lng" style="width: 200px" disabled></Input>
          <Button type="success" @click="showMapModal = true" class="ml20">设置地址</Button>
        </FormItem>
        <FormItem prop="remark" label="纬度">
          <Input v-model="form.lat" style="width: 200px" disabled></Input>
        </FormItem>
      </Form>
      <ModalSelectMapPoint v-model="showMapModal" :custCenter="{ lat: form.lat, lng: form.lng}" @posSelected="posSelected" />
    </div>
    <div slot="footer">
      <Button type="primary" @click="ok">添加</Button>
    </div>
  </Modal>
</template>
<script>
import ModalSelectMapPoint from '@/views/common/modals/ModalSelectMapPoint'
export default {
  name: 'ProjectList',
  components: {
    ModalSelectMapPoint
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      showModal: false,
      showMapModal: false,
      form: {
        "name": "",
        "area": "",
        "lat": 0,
        "lng": 0,
      }
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },

  },
  mounted: function () {
  },
  methods: {
    posSelected(el) {
      console.log(el)
      // this.showMapModal=false;
      this.form.lng = el.lng;
      this.form.lat = el.lat;
    },
    ok() {
      this.$axios.post(`api/auth/RegistProject`, this.form).then(res => {
        if (res.code == 0) {
          this.$router.push('/index');
        }
      });
    },
  }
}
</script>
<style scoped>
</style>
